<template>
    <!--岗位职责-->
    <div>
        <el-dialog :title="'岗位说明书'" :visible.sync="dlgVisible" v-model="dlgVisible"
                   :close-on-click-modal="false">
            <!--<el-dialog :title="operation ? '添加' : (optType?'编辑':'查看')" :visible.sync="dlgVisible" v-model="dlgVisible"
           :close-on-click-modal="false">-->
            <el-form ref="dlgFrom" :model="dlgFrom" class="demo-form-inline" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="公司:" prop="Factory">
                            <el-input type="text" v-model="dlgFrom.Factory" :disabled="true"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="版本:" prop="VersionsNo">
                            <el-input type="text" v-model="dlgFrom.VersionsNo" :disabled="!optType"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="部门:" prop="DptName">
                            <el-input type="text" v-model="dlgFrom.DptName" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="编制日期:" prop="ApprovalDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-date-picker type="date" v-model="dlgFrom.ApprovalDate" :disabled="!optType" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 100%"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="岗位:" prop="PostName">
                            <el-input type="text" :disabled="true" v-model="dlgFrom.PostName">
                                <el-button slot="append" icon="el-icon-search" @click="chosePost" :disabled="!optType"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="直属上级:" prop="PostSuperiorsName">
                            <el-input type="text" :disabled="true" v-model="dlgFrom.PostSuperiorsName">
                                <el-button slot="append" icon="el-icon-search" @click="chooseUser" :disabled="!optType"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="直属下级:" prop="PostSubordinateName">
                            <el-col :span="20">
                                <el-input type="text" v-model="dlgFrom.PostSubordinateName" :disabled="!optType"> </el-input>
                            </el-col>
                            <el-col class="line" :span="4">人</el-col>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合作伙伴:" prop="PostPartner">
                            <el-input type="text" v-model="dlgFrom.PostPartner" :disabled="!optType"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="外部关系:" prop="ExternalDpt">
                            <el-input type="text" v-model="dlgFrom.ExternalDpt" :disabled="!optType"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                
                <el-row style="margin-bottom: 20px">
                    <div class="myAlertSuccess"><span>基本素质</span></div>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="岗位概述" prop="PostSummarize" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-input type="textarea" v-model="dlgFrom.PostSummarize" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="dlgFrom.Details" style="width: 100%">
                        <el-table-column align="center" label="职责表述" prop="Overview"> </el-table-column>
                        <el-table-column align="center" label="工作任务" prop="Describe"> </el-table-column>
                        <el-table-column align="center" label="周期" prop="Period"> </el-table-column>
                        <el-table-column align="right" >
                            <template slot="header" slot-scope="scope" v-if="optType">
                                <el-button size="mini" @click="ShowDetails()" >添加</el-button>
                            </template>
                            <template slot-scope="scope" v-if="optType">
                                <el-button size="mini" @click="EditDetails(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="mini" type="danger" @click="DelDetails(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="性别:" prop="AstrictSex" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="dlgFrom.AstrictSex" placeholder="请选择" style="width: 100%" :disabled="!optType">
                                <el-option label="男优先" value="男优先"></el-option>
                                <el-option label="女优先" value="女优先"></el-option>
                                <el-option label="不限 " value="不限"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="年龄:" prop="AstrictAge" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="dlgFrom.AstrictAge" placeholder="请选择" style="width: 100%" :disabled="!optType">
                                <el-option label="25岁以下" value="25岁以下"></el-option>
                                <el-option label="26-35岁" value="26-35岁"></el-option>
                                <el-option label="36-45岁" value="36-45岁"></el-option>
                                <el-option label="45岁以上" value="45岁以上"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学历:" prop="AstrictEdu" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="dlgFrom.AstrictEdu" placeholder="本学历为最低要求" style="width: 100%" :disabled="!optType">
                                <el-option label="不限" value="不限"></el-option>
                                <el-option label="初中" value="初中"></el-option>
                                <el-option label="中专/高中" value="中专/高中"></el-option>
                                <el-option label="大专" value="大专"></el-option>
                                <el-option label="本科" value="本科"></el-option>
                                <el-option label="硕士" value="硕士"></el-option>
                                <el-option label="博士" value="博士"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="必备专业知识:" prop="AstrictSpecialty" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-input type="text" v-model="dlgFrom.AstrictSpecialty" :disabled="!optType"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="外语能力:" prop="AstrictLanguage" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="dlgFrom.AstrictLanguage" placeholder="请选择" style="width: 100%" :disabled="!optType" filterable allow-create>
                                <el-option label="无限制" value="无限制"></el-option>
                                <el-option label="基本读写能力" value="基本读写能力"></el-option>
                                <el-option label="具备口语沟通能力" value="具备口语沟通能力"></el-option>
                                <el-option label="外语上午沟通能力较强" value="外语上午沟通能力较强"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="本企业工作经验:" prop="AstrictSelfFactoryExp" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="dlgFrom.AstrictSelfFactoryExp" placeholder="请选择" style="width: 100%" :disabled="!optType">
                                <el-option label="不限" value="不限"></el-option>
                                <el-option label="1-2年" value="1-2年"></el-option>
                                <el-option label="3-5年" value="3-5年"></el-option>
                                <el-option label="6年以上" value="6年以上"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="本专业/本行业工作经验:" prop="AstrictIndustryExp" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="dlgFrom.AstrictIndustryExp" placeholder="请选择" style="width: 100%" :disabled="!optType">
                                <el-option label="不限" value="不限"></el-option>
                                <el-option label="1-2年" value="1-2年"></el-option>
                                <el-option label="3-5年" value="3-5年"></el-option>
                                <el-option label="6年以上" value="6年以上"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="计算机软件:" prop="AstrictSoftware" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="dlgFrom.AstrictSoftware" placeholder="请选择" style="width: 100%" :disabled="!optType" filterable allow-create>
                                <el-option label="文字，表格，幻灯片基础操作能力" value="文字，表格，幻灯片基础操作能力"></el-option>
                                <el-option label="具有一定平面设计能力" value="具有一定平面设计能力"></el-option>
                                <el-option label="具有一定机械制图能力" value="具有一定机械制图能力"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="资格证书:" prop="AstrictCet" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="dlgFrom.AstrictCet" placeholder="请选择" style="width: 100%" :disabled="!optType" filterable allow-create>
                                <el-option label="不限" value="不限"></el-option>
                                <el-option label="具有本行业从业类资格证书" value="具有本行业从业类资格证书"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <div class="myAlertSuccess">
                        <span>岗位能力</span>
                    </div>
                </el-row>
                <el-row>
                    <el-table :data="dlgFrom.Astricts" style="width: 100%">
                        <el-table-column align="center" label="能力项" prop="AstructName"> </el-table-column>
                        <el-table-column align="center" label="要求程度" prop="Explain"> </el-table-column>
                        <el-table-column align="right" v-if="optType">
                            <template slot="header" slot-scope="scope" v-if="optType">
                                <el-button size="mini" @click="ShowAstricts()">添加</el-button>
                            </template>
                            <template slot-scope="scope" v-if="optType">
                                <el-button size="mini" @click="EditAstricts(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="mini" type="danger" @click="DelAstricts(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>

                <el-row v-if="optType">
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="onSubmit('dlgFrom')">提交</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="addPostVisible" v-model="addPostVisible" width="75%" center append-to-body>
            <UserChoosejob :data="choosedPosts" :all="true" :single="false" @callback="chosePostcallFunction"></UserChoosejob>
        </el-dialog>
        <el-dialog :visible.sync="chooseJobVisibleUp" v-model="chooseJobVisibleUp" width="75%" center append-to-body>
            <UserChoosejob :data="chooseJobUp" :all="true" :single="false" @callback="chooseJobUpPostcallFunction"></UserChoosejob>
        </el-dialog>
        <el-dialog :visible.sync="chooseJobVisibleDown" v-model="chooseJobVisibleDown" width="75%" center append-to-body>
            <UserChoosejob :data="chooseJobDown" :all="true" :single="false" @callback="chooseJobDownPostcallFunction"></UserChoosejob>
        </el-dialog>

        <el-dialog title="工作内容" :visible.sync="AddDetailsVisible" v-model="AddDetailsVisible" center append-to-body>
            <el-form label-width="150px" ref="AddDetails" :model="AddDetails">
                <el-form-item label="职责表述:" prop="Overview">
                    <el-input v-model="AddDetails.Overview"></el-input>
                </el-form-item>
                <el-form-item label="工作任务:" prop="Relationship">
                    <el-input type="textarea" v-model="AddDetails.Describe"></el-input>
                </el-form-item>
                
                <el-form-item label="周期:" prop="Period">
                    <el-select v-model="AddDetails.Period" placeholder="请选择" style="width: 100%" :disabled="!optType">
                        <el-option label="每天" value="每天"></el-option>
                        <el-option label="每星期" value="每星期"></el-option>
                        <el-option label="每月" value="每月"></el-option>
                        <el-option label="每季度" value="每季度"></el-option>
                        <el-option label="每年" value="每年"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="AddDetailsVisible = false">取消</el-button>
                    <el-button type="primary"
                               v-on:click="DetailsSubmit()">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="岗位能力要求" :visible.sync="AddAstrictsVisible" v-model="AddAstrictsVisible" center append-to-body>
            <el-form label-width="150px" ref="AddAstricts" :model="AddAstricts">
                <el-form-item label="能力项:" prop="AstructName">
                    <el-input v-model="AddAstricts.AstructName"></el-input>
                </el-form-item>
                <el-form-item label="要求程度:" prop="Explain">
                    <el-input type="textarea" v-model="AddAstricts.Explain"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="AddAstrictsVisible = false">取消</el-button>
                    <el-button type="primary"
                               v-on:click="AstrictsSubmit()">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="选择人员" :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
            <UserChoose :data="choosedusers" :all="true" :single="false" @callback="checkupUsercallFunction"></UserChoose>
        </el-dialog>
    </div>
</template>

<script>
    import UserChoosejob from "../../components/UserChoosejob";
    import UserChoose from "../../components/UserChoose";
    import {
        AddPostExplainInfo,
        GetPostExplainInfo,
        UpdatePostExplainInfo,

        GetDepartmentOrg,
    } from "../../api/hr";
    export default {
        components: { UserChoosejob, UserChoose },
        data() {
            return {
                optType: false,   // 编辑/查看
                operation: false, // true:新增, false:编辑

                dlgVisible: false, // dlg 显示隐藏
                dlgFrom: {
                    Factory: "",
                    DptName: "",
                    PostId: "",
                    PostName: "",
                    PostSuperiorsName: "",
                    PostSuperiorsId: "",
                    PostSubordinateId: "",
                    PostSubordinateName: "",
                    Details: {},
                    Astricts: {}

                },

                addPostVisible: false,
                choosedPosts: [],

                chooseJobVisibleUp: false,
                chooseJobUp: [],
                chooseJobVisibleDown: false,
                chooseJobDown: [],

                AddDetailsVisible: false,
                AddDetails: {},
                AddAstrictsVisible: false,
                AddAstricts: {},

                addUserVisible: false,
                choosedUsers: [],
            };
        },
        methods: {
            reload() {
                this.$forceUpdate()
            },
            // 选中一行显示
            onShowDlg(pid) {
                this.reload();
                console.log("postExplain", this);
                if (pid == "" || pid == null) {
                    this.dlgFrom = {
                        Factory: "",
                        DptName: "",
                        PostId: "",
                        PostName: "",
                        
                        PostSuperiorsName: "",
                        PostSuperiorsId: "",
                        PostSubordinateId: "",
                        PostSubordinateName: "",
                        Details: [],
                        Astricts: []
                    };
                    this.dlgVisible = true;
                }
                else {
                    GetPostExplainInfo({ id: pid }).then((res) => {
                        this.dlgFrom = res.data.response;

                    });

                    this.dlgVisible = true;
                }
            },
            onSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let para = Object.assign({}, this.dlgFrom);
                            if (this.operation) {
                                //新增
                                AddPostExplainInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "添加成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.dlgVisible = false;
                                        this.$emit("callback"); //将值传给父组件
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            } else {
                                //修改
                                UpdatePostExplainInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "修改成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.dlgVisible = false;
                                        this.$emit("callback"); //将值传给父组件
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            }
                        } else {
                            this.$message({
                                message: "请检查必填项",
                                type: "error",
                                duration: 5000,
                            });
                            return false;
                        }
                    });
                });
            },

            // table opt
            RowAdd() {
                console.log("添加");
            },

            RowEdit(index, row) {
                console.log(index, row);
            },
            RowDelete(index, row) {
                console.log(index, row);
            },

            // chosePost
            chosePostcallFunction(newdata) {
                this.dlgFrom.PostName = newdata.Name;
                this.dlgFrom.PostId = newdata.Id;
                console.log(newdata);
                GetDepartmentOrg({ deptId: newdata.DepartmentId }).then((res) => {
                    if (res.data.code == 200) {
                        console.log(res.data);
                        if (res.data.data.deptName != null) {
                            this.dlgFrom.Factory = res.data.data.deptName;
                        } else {
                            this.dlgFrom.Factory = "";
                        }

                        this.dlgFrom.DptName = res.data.data.queryDeptName;
                    } else {
                        this.$message({
                            message: "获取部门信息出错",
                            type: "error",
                            duration: 5000,
                        });
                    }
                });
                console.log(this.dlgFrom);
                this.addPostVisible = false;
            },
            chosePost() {
                this.addPostVisible = true;
            },

            chooseJobUpPostcallFunction(newdata) {
                this.dlgFrom.PostSuperiorsName = newdata.Name;
                this.dlgFrom.PostSuperiorsId = newdata.Id;
                this.chooseJobVisibleUp = false;
            },
            
            chooseJobUpDlg() {
                this.chooseJobVisibleUp = true;
            },
            // AddUser Fucation
            checkupUsercallFunction(newdata) {
                if (newdata.length > 1) {
                    this.$message({
                        message: "不允许选择多人",
                        type: "error",
                    });
                    return;
                }
                this.dlgFrom.PostSuperiorsId = newdata[0].Id;
                this.dlgFrom.PostSuperiorsName = newdata[0].UserNameClaim;

                this.addUserVisible = false;
            },
            chooseUser() {
                this.addUserVisible = true;
            },

            chooseJobDownPostcallFunction(newdata) {
                this.dlgFrom.PostSubordinateName = newdata.Name;
                this.dlgFrom.PostSubordinateId = newdata.Id;
                this.chooseJobVisibleDown = false;
            },
            chooseJobDownDlg() {
                this.chooseJobVisibleDown = true;
            },

            ShowDetails() {
                this.AddDetailsVisible = true;
                this.AddDetails = { };
            },
            ShowAstricts() {
                this.AddAstrictsVisible = true;
                this.AddAstricts = { };
            },

            DelDetails(index,row) {
                this.$confirm("确认要删除吗？", "提示", {
                    type: "warning",
                }).then((_) => {
                    this.dlgFrom.Details.splice(this.dlgFrom.Details.indexOf(row), 1);
                });
            },
            DelAstricts(index,row) {
                this.$confirm("确认要删除吗？", "提示", {
                    type: "warning",
                }).then((_) => {
                    this.dlgFrom.Astricts.splice(this.dlgFrom.Astricts.indexOf(row), 1);
                });
            },
            EditDetails(index, row) {
                this.AddDetails = row;
                this.AddDetails.rowIndex = index;
                this.AddDetailsVisible = true;
            },
            EditAstricts(index, row) {
                this.Astricts = row;
                this.Astricts.rowIndex = index;
                this.AddAstrictsVisible = true;
            },

            DetailsSubmit(type) {
                console.log("this.dlgFrom.Details:", this.AddDetails)

                if (this.AddDetails.rowIndex != undefined) {   // 编辑
                    let para = Object.assign({}, this.AddDetails);
                    this.$set(this.dlgFrom.Details, this.AddDetails.rowIndex, para);
                }
                else {  // 添加
                    this.dlgFrom.Details.push(this.AddDetails);
                }
                this.AddDetailsVisible = false;
            },
            AstrictsSubmit() {
                if (this.AddAstricts.rowIndex != undefined) {   // 编辑
                    let para = Object.assign({}, this.AddAstricts);
                    this.$set(this.dlgFrom.Astricts, this.AddAstricts.rowIndex, para);
                }
                else {  // 添加
                    this.dlgFrom.Astricts.push(this.AddAstricts);
                }
                this.AddAstrictsVisible = false;
            },
        },

    };
</script>

<style scoped>
    .tableTitle {
        position: relative;
        margin: 0 auto;
        height: 1px;
        background-color: #4cff00;
        text-align: center;
        font-size: 16px;
        color: rgba(101, 101, 101, 1);
    }
    .myAlertSuccess {
        background-color: #409eff;
        color: #FFF;
        padding: 8px 16px;
        border-radius: 4px ;
        text-align: center;
        font-size: 16px;
    }
</style>